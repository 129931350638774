.loader {
  display: block;
  list-style-type: none;
  padding: 0;
  margin: 7rem auto;
  max-width: 55rem;
  justify-content: space-between;
  align-items: center;
  border: 16px solid #80A6EA;
  border-radius: 50%;
  border-top: 16px solid white;
  width: 120px;
  height: 120px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
}
  
  /* Safari */
  @-webkit-keyframes spin {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }